import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';

import { Element,scroller } from 'react-scroll';
import { AuthContext } from "../../components/Auth/AuthDataProvider.jsx";
import apiUtil from "../../api/apiUtil.jsx";
import api from "../../api/api.jsx";
import ldsApi from "../../api/ldsApi.jsx";
import moment from 'moment';
import { Container, Row, Col, Button, Card, ToggleButton, ToggleButtonGroup, Form, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CustomDialog2 from '../../components/Dialog/CustomDialog2.jsx';
import 'react-circular-progressbar/dist/styles.css';
import { faDownload, faMagnifyingGlass, faPlus, faWarning, faPlusCircle} from '@fortawesome/free-solid-svg-icons'
import { 
  Box,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from '@material-ui/core';
import Filterimg from '../../assets/img/icons/filter.png';
import { ReactComponent as ArrowDownIcon } from '../../assets/img/left_menu/arrow-down.svg';
import Table from '../../components/Table/allocationTable.jsx';
import CustomInput from '../../components/CustomFields/CustomInput.jsx';
import { ReactComponent as CalendarIcon } from '../../assets/img/icons/calendar.svg';

import CustomButton from '../../components/CustomFields/CustomButton.jsx';
import CustomDialog from '../../components/Dialog/CustomDialog.jsx';
import DropdownMenu from '../../components/Dropdown/DropdownMenuFilter.jsx';
import CustomAutosuggest from '../../components/CustomFields/CustomAutosuggest.jsx';
import CustomGuideStepper from '../../components/Stepper/CustomGuideStepper.jsx';

import JobPreviewDialog from '../Jobs/Partial/JobPreviewDialog.jsx';
import OrderDetailForm from '../Dashboard/OrderDetailForm';
import socketIOClient from "socket.io-client";
import { Observable } from 'rxjs/Observable';
import {RoleAccessService} from '../../data/role-access'; 
import Tour from 'reactour';
import HtmlParser from 'react-html-parser';
import CustomDateRangePicker from '../../components/CustomFields/CustomDateRangePicker.jsx';
import Pagination from "../../components/Pagination/pagination"
import _ from "lodash";

const { 
  REACT_APP_JOB_UPDATE_SOCKET_URL,
  REACT_APP_LDS_API_URL,
} = process.env;

 
class Allocation extends Component {
  static contextType = AuthContext;
  rowData =null;
  // collection_only=null;
  collection_do = null;
  target_date = null;
  target_application_company_id = null;
  target_drop_off_worker_id = null;
  target_vendor_id = null;
  target_status_id = null;
  index_num = null;
  target_address = null;
  pages = 1;
  limits = 10;
  search = '';
  DoDialogOrder=null;
  tableOrders= null;

  scrollSpeed = 10; // Adjust this value to control the scroll speed
  scrollZoneHeight = 100; // Adjust this value to control the scroll zone height

  scrollIntervalId = null;

  constructor(props){
    super(props); 
    this.containerRef = React.createRef();
    this.scrollbarRef = React.createRef();
    this.handleDragStart = this.handleDragStart.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);


    this.containerId = 'scroll-container';
    this.scrollUpThreshold = 50; // Threshold for scrolling up (mouse moving downward)
    this.scrollDownThreshold = window.innerHeight - 50; // Threshold for scrolling down (mouse moving upward)
    this.scrollIntervalId = null;
    this.isScrollingUp = false;
    this.isScrollingDown = false;
    
    this.pageName = 'jobList';
    let initStatusSelected = (this.props.location && this.props.location.state && this.props.location.state.index && this.props.location.state.index > 0) ? this.props.location.state.index : null;
    let initOrderStatus = (this.props.location && this.props.location.state && this.props.location.state.order_status && this.props.location.state.order_status > 0) ? this.props.location.state.order_status : null;
    let isShouldDisableSocketToast = (this.props.location && this.props.location.state && this.props.location.state.isShouldDisableSocketToast && this.props.location.state.isShouldDisableSocketToast === true) ? this.props.location.state.isShouldDisableSocketToast: false;
    this.refTable = React.createRef();
    this.refTableSearch = React.createRef();

    let rangeDate = {
      startDate: moment(),
      endDate: moment(),
    };
    let rangeDateItem = localStorage.getItem(this.pageName + '_rangeDate');
    if(rangeDateItem && rangeDateItem !== ''){
      try {
        rangeDate = JSON.parse(rangeDateItem);
      }catch(e){}
    }
    // var selectRow = [];
    let hiddenColumns = [];

    this.state = {
      grantedAccess : new RoleAccessService(),
      currentRole: window.localStorage.getItem('current_role') || null,
      current_status_name: "",
      isShouldDisableSocketToast: isShouldDisableSocketToast,
      rangeDate: rangeDate,
      startDate: rangeDate.startDate,
      endDate: rangeDate.endDate,

      user: JSON.parse(localStorage.getItem('user')),
      isLoadingStatus: true,
      status: [],
      tableStatus: [],
      isLoadingDelete: false,
      statusSelected: initStatusSelected,
      order_status: initOrderStatus,
      collection_only: false,
      nameGroupDialog: false,
      exitGroupDialog: false,
      isLoading: true,
      grouping: true,
      data: [],
      status_total: 0,
      total: 0,
      workers: [],
      selectedRow:[],
      openDialog: false,
      openDoDialog: false,
      openDuplicateDialog: false,
      openDialogBatchUpdate: false,
      openDialogBatchUpdateConfirm: false,
      openDeleteDialog:false,
      openBulkDeleteDialog:false,
      openWarnDialog: false,
      route: null,
      openDialogItem: null,
      openDialogAutoAssign:false,
      invalidAddress:[],
      do_numbers: null,
      openPreviewDialog: false,
      openPreviewDialogItem: null,
      autoAssign: false,
      defaultGroupOrder: -1,
      collection_do_number: [],
      collection_erference_no: null,
      isTourOpen: false,
      openSkipDialog: false,
      collection_erference_noValidated:false,
      hiddenColumns: hiddenColumns,
      value: 1,
      tabValue: 0,
      uploaders: [],
      selected_uploader: '',
      allocation_groups: [],
      group_name: '',
      delivery_date: '',
      selected_group: '',
      allocated_menu: false,
      auto_allocate: 'Allocate',
      allocation_group_name: [],
      searched: '',
      filter_data: [],
      toUpdate: null,
    }
  }
  

  componentDidMount() {
    const { isOnBoarding } = this.context;
    if(isOnBoarding){
      this.setState({
        isTourOpen: true,
      });
    }

      // this.callWorkerDriverReadApi();
      // this.callGroupNameApi();
      // this.callAllocationGroup()
      this.loadStatusApi(() => {
        this.callReadApi();
      }); 

  }

  callInitApis = () => {
    this.loadStatusApi(()=> {
        this.callReadApi();
    });
  }

  handleDragStart = (event) => {

    // Add event listeners to the element where dragging is happening
    document.addEventListener('dragover', this.handleDragOver);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

  handleDragOver = (event) => {
    const { clientY } = event;
    const pageHeight = Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    );
    const scrollSpeed = 80; // Adjust this value to control the scroll speed

        // Scroll the container based on mouse movement
        if (clientY <= 50) {
          // console.log('up');
          window.scrollTo({
            top: window.scrollY - scrollSpeed,
            behavior: 'smooth'
          });
        } else if (clientY >= 300) {
          // console.log('down');
          // window.scrollBy(0, scrollSpeed);
          window.scrollTo({
            top: window.scrollY + scrollSpeed,
            behavior: 'smooth'
          });
        } else {
          // console.log('stop');
        }
  };
  
  handleMouseUp = () => {
    document.removeEventListener('dragover', this.handleDragOver);
    document.addEventListener('mouseup', this.handleMouseUp);
  };

    /* VENDOR API */
    callVendorReadApi = (search = '', callback = null) => {
      const { accessToken } = this.context;
  
        let param = {
          page: 1,
          take: apiUtil.getDefaultPageSize(),
          is_active: true,
        };
  
        api.read('vendors/active_vendor', param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              let result = (data.result && data.result.length > 0) ? data.result : [];
  
              let arr = [];
              if(result && result.length > 0){
                arr = result.map((item, i) => {
                  let value = item.company_id;
                  let label = item.company_name;
  
                  if(arr.findIndex(x => x.value === value) === -1){
                    return {
                      value: value,
                      label: label,
                      item: item
                    }
                  } else {
                    return null;
                  }
                });
        
                // this.setState({
                //   vendors: arr,
                // }, () => {
                  if(callback){
                    callback(arr);
                  }
                // });
              } else {
                // this.setState({
                //   vendorItems: [],
                // }, () => {
                  if(callback){
                    callback([]);
                  }
                // });
              }
            }, (error, type) => {
              // this.setState({
              //   vendorItems: [],
              // }, () => {
                if(callback){
                  callback([]);
                }
              // });
            });
        });
    }
    /* END VENDOR API */

  loadStatusApi = (callback = null) => {
    this.callStatusApi((data, total) => {
      if(data && data.length > 0){
        let order_status = this.state.order_status;
        let status = [];
        data.map((item, i) => {
          if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assign-pending" || item.status_details.status.replace(' ', '-').trim().toLowerCase() == "on-hold") {
            return status.splice(1, 0, {
              order: 7,
              id: item.status_details.id,
              status: "On Hold",
              text: "On Hold",
              total: item.total_job,
            });
            // status[0].push(objectVal)
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "not-assigned") {
            order_status = order_status  ? `${order_status},${ item.status_details.id}`: item.status_details.id;
            return status.splice(2, 0, {
              order: 1,
              id: item.status_details.id,
              status: "Pending",
              text: "Pending",
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "assigned") {
            order_status = order_status ? `${order_status},${ item.status_details.id}`: item.status_details.id;
            return status.splice(3, 0, {
              order: 2,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "acknowledged") {
            return status.splice(4, 0, {
              order: 3,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "in-progress") {
            return status.splice(5, 0, {
              order: 4,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "completed") {
            return status.splice(6, 0, {
              order: 5,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "failed") {
            return status.splice(7, 0, {
              order: 6,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "cancelled") {
            return status.splice(8, 0, {
              order: 8,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replace(' ', '-').trim().toLowerCase() == "self-collect") {
            return status.splice(9, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          } else if(item.status_details.status.replaceAll(' ', '-').trim().toLowerCase() == "assigned-to-vendor") {
            order_status = order_status  ? `${order_status},${ item.status_details.id}`: item.status_details.id;
            return status.splice(9, 0, {
              order: 9,
              id: item.status_details.id,
              status: item.status_details.status,
              text: item.status_details.status,
              total: item.total_job,
            });
          }
        });
  
        status = status.sort(function(a, b) {
          return (a.order - b.order);
        });
        let tableStatus = status;
        status.unshift({
          id: 0,
          status: 'Total Orders',
          total: total,
          
        });
  
        this.setState({
          status: status,
          status_total: total,
          tableStatus: tableStatus,
          order_status: order_status,
        }, () => {
          if(callback) {
            callback();
          }
        });
      } else {
        if(callback) {
          callback();
        }
      }
    });
  }

  /* API */
  callStatusApi = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());

      let param = {
        start_date: startDate,
        end_date: endDate,
      };

      ldsApi.read('orders/job/stats', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data && data.result){
              let total = data.total_delivery;
              let result = Object.keys(data.result).map((k) => data.result[k]);
              
              if(result && result.length > 0){
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(result, total);
                  }
                });
              } else {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(null, 0);
                  }
                });
              }
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null, 0);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null, 0);
              }
            });
          });
      });
    });
  }
  
  isCollectionFailed = (deliOrder) => {
    return deliOrder.failed_collection ? deliOrder.failed_collection : false;
  }

  callReadApi = (search = '', do_num = '', uploader = '') => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading: true
    }, () => {
      let startDate = moment(this.state.rangeDate && this.state.rangeDate.startDate).format(apiUtil.getDefaultDateFormat());
      let endDate = moment(this.state.rangeDate && this.state.rangeDate.endDate).format(apiUtil.getDefaultDateFormat());
      let param = {
        page: this.pages,
        take: this.limits,
        search: this.state.searched ? this.state.searched.trim() : this.state.searched,
        order_status: this.state.order_status,
        vendor_id: false,
        non_groupped_order: true,
        allocation_step: 1,
        start_date: startDate,
        end_date: endDate,
        sort: "latest"
      };
      
      // if(order)
      ldsApi.read('allocation/orders', param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let collection_only = false;
            data.result && data.result.length > 0 && data.result.map(dd=> {
              
              if (dd.orders  && dd.orders.length == 1) {
                collection_only = true
              }
            })
            // let sorted_data = data.result && data.result.sort((a,b) => {if (a.orders['0']["id"] > b.orders['0']["id"]) { return -1 } else {return 1}})
            // let sorted_data = data.result.sort((a,b) => {if (a.orders['0']["job_type"] > b.orders['0']["job_type"]) { return -1 } else {return 1}})
            data.result.forEach(obj => {
              obj.orders.sort((a, b) => {
                if (a.job_type < b.job_type) {
                  return -1;
                }
                if (a.job_type > b.job_type) {
                  return 1;
                }
                return 0;
              });
            });
            this.setState({
              data: data.result ? data.result : [],
              collection_do_number : data.do_number && data.do_number.length > 0 ? data.do_number : [],
              uploaders: (data.uploaders && data.uploaders.length > 0) ? data.uploaders : [],
              total: data.total,
              isLoading: false,
              collection_only
            });
            // this.loadStatusApi();
          }, (error, type) => {
            this.setState({
              data: [],
              total: 0,
              uploaders:[],
              isLoading: false,
              collection_only : false,
            });
          });
      });
    });
  }

  changeTableLimit = (e) => {
    this.limits = e.target.value;
    this.callReadApi();
  }

  callUpdateIsActiveApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + row.id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }

  callDeleteApi = (row,indexNum=null, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      isLoadingDelete:true,
    })
   
      let param = {
        destroy_all: true,
        allocation: true,
      };
      let selectedRow = row;
      let ids = '';
  
      selectedRow.map((item, i) => { ids += item.id +','; });


      ids = ids.slice(0, -1);
      if ((selectedRow.length > 0)) {
        ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
          .then((result) => {
            apiUtil.parseResult(result, (data) => {
              this.setState({
                isLoadingDelete:false,
              })
              if(callback){
                callback();
              }
            }, (error, type) => {
              if(indexNum != null) {
                this.state.data[indexNum].orders.map((obj) => {
                  obj.tableData.checked = false;
                  obj.is_selected = false;
                });
                this.state.data[indexNum].is_selected = false;
              }
              this.setState({ 
                openDeleteDialog: false,
                isLoadingDelete:false,
                data: this.state.data
              }, ()=> {
                //this.state.data.map(dd => dd.is_selected == true ? dd.is_selected = false : dd.is_selected)
                //this.setState({
                //  data : this.state.data
                //})
              });  
              
              this.doDialogOrder = null;
              apiUtil.toast(
                error,
                "check_circle"
              );
            });
        });
      }
  }
  
  callBulkDeleteApi = (row, callback = null) => {
    const { accessToken } = this.context;
    
    this.setState({
      isLoading:true,
    })
   
    let param = {
      destroy_all: true,
      allocation: true,
    };
    let selectedRow = row;
    let ids = '';
  
    selectedRow.map((item, i) => { ids += item.id +','; });


    ids = ids.slice(0, -1);
    if ((selectedRow.length > 0)) {
      ldsApi.delete('delete/orders/multiple?id=' + ids, param, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading:false,
            })
            if(callback){
              callback();
            }
          }, (error, type) => {

            this.setState({ 
              isLoading:false,
              openDeleteDialog: false,
            });  
            
            this.doDialogOrder = null;
            apiUtil.toast(
              error,
              "check_circle"
            );
          });
      });
    }
  }

  deleteDialog = () => {
    let check_duplicate = null
    this.DoDialogOrder && this.DoDialogOrder.orders && this.DoDialogOrder.orders.map((dd) => 
      dd.do_number.includes("-DULP") ? check_duplicate = true : null
    )
    return <Modal centered show={this.state.openDeleteDialog} onHide={() => {
            this.setState({
              openDeleteDialog: false,
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you sure?</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
              { !check_duplicate? 
                <div className="text-center mb-4">This is <span className="text-danger">NOT</span> a duplicated job. Deleting this job will permanently remove the job from the dashboard. Continue?</div>
                : <div className="text-center mb-4">Delete this duplicated job?</div>
              }
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 border-delete-b" onClick={() => {
                this.setState({
                  openDeleteDialog: false,
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 border-delete" 
              disabled={this.state.isLoadingDelete}
              onClick={() => {
                  let row = this.DoDialogOrder.orders;
                  let indexNum = this.DoDialogOrder.indexNum;
                    this.callDeleteApi(row,indexNum, () => {
                      apiUtil.toast('Successfully Deleted', 'check_circle');
                      this.setState({
                        openDeleteDialog: false,
                      }, ()=> {
                        this.DoDialogOrder = null;
                      })
                      this.callReadApi();
                    })
                }}>
                Delete
              </Button>
            </div>
            </div>
          </Modal>
  }
  
  bulkDeleteDialog = () => {
    return <Modal centered show={this.state.openBulkDeleteDialog} onHide={() => {
            this.setState({
              openBulkDeleteDialog: false,
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you sure?</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
              Deleting this job will permanently remove the job from the dashboard. Continue?
              <div className="d-flex justify-content-center mt-4">
                <Button variant="secondary" className="mx-2 px-4 py-2 border-delete-b" onClick={() => {
                  this.setState({
                    openBulkDeleteDialog: false,
                  });
                }}>
                  Cancel
                </Button>
                <Button variant="primary" className="mx-2 px-4 py-2 border-delete" 
                onClick={() => {
                    let row = [];
                    let selected_orders = this.state.data.filter(obj => obj.is_selected == true)
                    let is_collection_selected = []
                    let inner_selected_order = []
                    let unselected_selected_order = []
                    let is_has_vendor_order = [];
                    let alert_message = '';
                    let has_issue = false
                    

                    selected_orders.map((obj) => {                       
                       is_has_vendor_order = obj.orders.filter(obj => obj.application_company_id != this.state.user.owner_info.application_company_id);
                       is_collection_selected = obj.orders.filter(obj => obj.job_type == 'Collection' && obj.is_selected && obj.is_selected == true);
                       inner_selected_order = obj.orders.filter(obj => obj.is_selected && obj.is_selected == true);
                       if(is_has_vendor_order.length > 0) {
                         apiUtil.toast('Please check with Operations for changes', 'check_circle');
                         has_issue = true; 
                       }else if(is_collection_selected.length > 0 && obj.orders.length != inner_selected_order.length) {                                              
                         apiUtil.toast('Please delete Delivery order(s) before the Pick Up Order '+is_collection_selected[0].order_number, 'warning', 'error'); 
                         alert_message = '';
                         has_issue = true;                         
                       } else {
                         row = [...row,...inner_selected_order];                                          
                       }
                       
                    });

                    if(has_issue == false) {
                      this.callBulkDeleteApi(row, () => {
                        apiUtil.toast('Successfully Deleted', 'check_circle');
                        this.callReadApi();
                      })                                           
                    }
                    this.setState({
                      openBulkDeleteDialog: false,
                    })
                  }}>
                  Delete
                </Button>
              </div>
            </div>
          </Modal>
  }

  duplicateDialog = () => {
    let company_name = this.DoDialogOrder && this.DoDialogOrder.company_name
    let vendor = this.DoDialogOrder && this.DoDialogOrder.orders.filter((obj) => obj.application_company_id != this.state.user.owner_info.application_company_id);
    return <Modal centered show={this.state.openDuplicateDialog} onHide={() => {
            this.setState({
              openDuplicateDialog : false,
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you sure?</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
            <div className="text-center mb-4">This will put your <span className="font-medium">{this.DoDialogOrder && this.DoDialogOrder.orders && this.DoDialogOrder.orders.length}</span> selected jobs into a new cluster {(vendor && vendor.length > 0 || company_name == 'No Customer') ? '' : 'for ' }<span className="font-medium">{(vendor && vendor.length > 0 || company_name == 'No Customer') ? '' : company_name }</span></div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 buttom-primary-dailog-cancel" onClick={() => {
                this.setState({
                  openDuplicateDialog: false,
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 buttom-primary-dailog" 
                disabled ={this.state.isLoading ? true : false}
                onClick={() => {
                  let customData = this.DoDialogOrder.orders;
                  let indexNum = this.DoDialogOrder.indexNum;
                  let is_deli_only = this.state.data[indexNum].orders.filter((obj) => obj.job_type == 'Collection' && obj.is_selected);
                  let pending_id = this.state.tableStatus.filter((order_status) => order_status.status == 'Pending')[0].id;
                  let org_data = _.cloneDeep(this.state.data[indexNum].orders);
                  if(vendor.length > 0 && !is_deli_only.length > 0) {
                    customData = this.state.data[indexNum].orders;
                  }

                  customData.map((order) => {
                    if(vendor.length > 0) {
                      order['amount'] = 0.00
                      order['driver_payout'] = 0.00
                      order['vendor_payout'] = 0.00
                      order['order_status'] = null;                    
                      order['vendor_id'] = null;
                      order['order_status_id'] = pending_id;      
                      order['company_name'] = null; 
                      order['customer_id'] = null;                   
                    }
                    
                    order.prev_id = order.id;
                    ['id','order_number','order_status','extra_workers','uploader','reference_no', 'department'].forEach(e => delete order[e]);
                    order.drop_off_date = moment(order.drop_off_date).format("YYYY-MM-DD");
                    order.is_duplicate = true;
                    order.is_calculate = true;
                    order.job_steps = [{job_step_id: null}]
                    order.order_packages.map((pack, index) => {
                      if (pack.package_id === 1) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'XS'
                        order.xs_packages =  order.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 2) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'S'
                        order.s_packages =  order.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 3) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'M'
                        order.m_packages =  order.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 4) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'L'
                        order.l_packages =  order.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 5) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'Pallet'
                        order.pallets =  order.order_packages[index]['package_quantity']
                      }
                      if (pack.package_id === 6) {
                        delete pack['created_at'];
                        delete pack['updated_at'];
                        delete pack['application_company_id'];
                        delete pack['order_id'];
                        delete pack['id'];
                        order.order_packages[index]['name'] = 'Oversized Package'
                        order.oversized_packages =  order.order_packages[index]['package_quantity']
                      }
                    })
                  });
                  customData.sort((a,b) =>  a.job_type - b.job_type )
                  this.callCreateApi(customData, (result) => {
                    let orders = [];
                    if(result.status) {
                      this.state.data[indexNum].orders = org_data;
                      this.setState({
                        data: this.state.data  
                      },() => {
                        // let sorted_data = result.saved_orders && result.saved_orders.sort((a,b) => {if (a.orders['0']["id"] > b.orders['0']["id"]) { return -1 } else {return 1}})
                        orders = result.saved_orders.sort((a, b) => a.job_type.localeCompare(b.job_type));
                        
                        let driverpayout = 0 
                        result.saved_orders.map((order) => {
                          let driverPayout = 0;
                          if(order.driver_payout != null) {                       
                            driverpayout = driverpayout + parseFloat(order.driver_payout)
                          }
                        })
                        this.state.data[indexNum].orders.map((obj) => {
                            obj.tableData.checked = false;
                            obj.is_selected = false;
                        });
                        let checkCol = orders.filter((obj) => obj.job_type == 'Collection')
                        let addCol = this.state.collection_do_number.concat(checkCol)
                        this.setState({
                          collection_do_number: addCol,
                        })
                        if(checkCol.length > 0) {
                          let data = [{is_selected: false,company_name: (vendor.length > 0 ? 'No Customer' : this.DoDialogOrder.company_name), orders: orders, driver_name: (vendor.length > 0 ? '' : this.DoDialogOrder.driver_name), driver_payout: driverpayout}];
                          this.setState({ 
                            data: [...data,...this.state.data]
                          }, ()=> {
                            this.state.data.map(dd => dd.is_selected == true ? dd.is_selected = false : dd.is_selected)
                            this.setState({
                              data : this.state.data
                            })
                          });                            
                        } else {
                          let order_driver_payout = 0
                          orders.map((dd) => {order_driver_payout = dd.driver_payout != null ? parseFloat(order_driver_payout) + parseFloat(dd.driver_payout) : 0})
                          let data = this.state.data
                          data[indexNum].is_selected = false;
                          data[indexNum].driver_payout = order_driver_payout != NaN ? parseFloat(this.state.data[indexNum].driver_payout) + parseFloat(order_driver_payout) : 0.00;
                          data[indexNum].orders = [...this.state.data[indexNum].orders,...orders];
                          this.setState({
                            data: data
                          });  
                          this.tableOrders = this.state.data[indexNum].orders;                        
                        }                          
                        });
                        
                    }
                    this.setState({
                      openDuplicateDialog: false,
                      isLoading: false,
                    }, () => {
                      apiUtil.toast('Successfully Updated', 'check_circle');
                      this.DoDialogOrder = null;
                    });
                  });
                }}>
                Confirm
              </Button>
            </div>
            </div>
          </Modal>
  }
  /*End Mass Delete Api*/

  callUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result[0] : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            if(error && error.email && error.email.length > 0){
              apiUtil.toast(error.email[0], 'warning', 'error');
            } else if(error && error !== ''){
              apiUtil.toast(error, 'warning', 'error');
            }
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }
  /* END API */

  handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (this.state.do_numbers == null) {
      this.setState({
        selectedDoNumberValidated: true,
        openDialogBatchUpdate : true,
        collection_erference_no: null,
        do_numbers: null,
      });
    } else if (this.state.collection_erference_no == null) {
      this.setState({
        collection_erference_noValidated: true,
        openDialogBatchUpdate : true,
      });
    } else {
      let selected_do_number = this.state.do_numbers.replace(/\n|\r|,/g, ",")
      selected_do_number = selected_do_number.split(",").map(str => str.trim()).join(",") // trim individual words and join with commas
      let data = {
        delivery_do : selected_do_number,
        collection_reference : this.state.collection_erference_no ,
      }
      this.callUpdateDoApi(data, (row) => {
        this.setState({
          collection_erference_no: null,
          do_numbers : null,
          openDialogBatchUpdate : false,
        });
        this.callInitApis();
      });
    }
  }

  customDialogBatchUpdate = () => {
    return <Modal centered show={this.state.openDialogBatchUpdate} onHide={() => {
      this.setState({
        openDialogBatchUpdate : false,
        collection_erference_no: null,
        selectedDoNumberValidated: false,
        do_numbers: null,
      });
      }} className="width-modal">
      <Modal.Header className="p-4" closeButton>
      <div className="text-center font-medium fs-5 d-flex justify-content-start w-100">Change Pick Up</div>
      </Modal.Header>
      <div className="justify-content-center p-4">
      <Container fluid>
        <Row className="p-4">
         <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900">
           <div className="max-xs-width-control-900 w-25 px-3 text-end font-medium">D.O Number *</div>
          <div className="w-75 max-xs-width-control-900">
           <textarea className='form-control w-100' rows="5"
          value={this.state.do_numbers}
          onChange={e => {
            this.setState({
                do_numbers: e.target.value.replace(/[,]/g, ""),
            })
          }}
          />
          {this.state.selectedDoNumberValidated && <span style={{color:"red"}}>Do Number(s) are required<br/><br/></span>}
          <span style={{fontSize: '10px'}}>Copy and paste / enter / DO number into the box. One D.O. No. per line.</span>
          </div>
          </Col>
          <Col xs={12} md={12} className="d-flex py-2 max-xs-width-control-900">
            <div className="w-25 px-3 text-end max-xs-width-control-900 font-medium">New Pick Up Ref DO</div>
            <div className="w-50 d-flex justify-content-start max-xs-width-control-900 flex-column">
            <select className="max-xs-width-control-900 form-control ms-2" style={{width:"100%", border: "1px solid #E0E1E4", borderRadius: "4px",padding:"", backgroundColor: 'transparent'}}
                placeholder={'Select pick up reference here...'}
                value={this.state.collection_erference_no}
                native
                disableUnderline
                error={this.state.collection_erference_noValidated}
                onChange={(e) => {
                  this.setState({
                    collection_erference_no: e.target.value
                  })
                }}
              >
          <option value="" selected> Select Pick Up Reference </option>
          {this.state.collection_do_number && this.state.collection_do_number.map((dd, index)=> 
            <option value={dd.order_number}>{dd.order_number}</option>
          )}
        </select>
        {this.state.collection_erference_noValidated && <span style={{color:"red"}}>Select Pick Up Reference<br/><br/></span>}
        {/* <input type="text" value={department} className="form-control ms-2" disabled/> */}
            </div>
          </Col>
        </Row>
      </Container>
      <div className="d-flex justify-content-center">
        <Button variant="secondary" className="mx-2 px-4 py-2 buttom-primary-dailog-cancel" onClick={() => {
          this.setState({
            openDialogBatchUpdate: false,
            collection_erference_no: null,
            selectedDoNumberValidated: false,
            do_numbers: null,
          });
        }}>
          Cancel
        </Button>
        <Button variant="primary" className="mx-2 px-4 py-2 buttom-primary-dailog" 
        disabled={this.state.do_numbers || this.state.collection_erference_no ? false : true}
        onClick={(e) => {
             this.setState({
              openDialogBatchUpdateConfirm: true,
              openDialogBatchUpdate: false,
             })
          }}>
          Save Changes
        </Button>
      </div>
      </div>
    </Modal>
    
  }

  dialogBatchUpdateConfirm = () => {
    let company_name = this.DoDialogOrder && this.DoDialogOrder.orders &&  this.DoDialogOrder.orders['0'].customer &&  this.DoDialogOrder.orders['0'].customer.company_name
    return <Modal centered show={this.state.openDialogBatchUpdateConfirm} onHide={() => {
            this.setState({
              openDialogBatchUpdateConfirm : false,
              collection_erference_no: null,
              do_numbers: null,
            });
            }}>
            <Modal.Header className="p-4" closeButton>
            <div className="text-center font-medium fs-5 d-flex justify-content-center w-100">Are you sure?</div>
            </Modal.Header>
            <div className="justify-content-center p-4">
            <div className="text-center mb-4">Make sure all your delivery points are tied to the correct pick up points. Any pick up that do not have delivery points will not be reflected in the subsequent steps.</div>
            <div className="d-flex justify-content-center">
              <Button variant="secondary" className="mx-2 px-4 py-2 buttom-primary-dailog-cancel" onClick={() => {
                this.setState({
                  openDialogBatchUpdateConfirm: false,
                  collection_erference_no: null,
                  do_numbers: null,
                });
              }}>
                Cancel
              </Button>
              <Button variant="primary" className="mx-2 px-4 py-2 buttom-primary-dailog" onClick={(e) => {
                this.setState({
                  openDialogBatchUpdateConfirm: false,
                  collection_erference_no: null,
                  do_numbers: null,
                });
                this.handleSubmit(e);
                }}>
                Confirm
              </Button>
            </div>
            </div>
          </Modal>
  }

  callMassUpdateStatusApi = (row, callback = null) => {
    const { accessToken } = this.context;
    //console.log(row.data[0])
    if (!row.data[0].id.length > 0) {
      apiUtil.toast('You must select order!', 'warning');
      return
    }
    this.setState({
      isLoading: true
    }, () => {
      ldsApi.create('orders/assign/order', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.result && data.result.length > 0) ? data.result : null;
            this.loadStatusApi()
            if(result){
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                  this.callReadApi();
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
              openDialogChangePayout: false,
              openDialogUnassign: false
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  callAutoAssignApi = (row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      autoAssign: true
    }, () => {
      ldsApi.create('auto_assign/orders', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            if(data.status){
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(data);
                }
              });
            } else {
              this.setState({
                autoAssign: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle")
            this.setState({
              autoAssign: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });
  }

  /* WORKER API */
  callWorkerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      disabled: false,
      // take: apiUtil.getDefaultPageSize(),
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true,
    };

    api.read('workers', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];

          let arr = [];
          if(result && result.length > 0){
            arr = result.map((item, i) => {
              let value = item.id;
              let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

              if(arr.findIndex(x => x.value === value) === -1){
                return {
                  value: value,
                  label: label,
                  item: item
                }
              } else {
                return null;
              }
            });
            this.setState({
              workers: arr.sort((a, b) => {
                      const nameA = a.label?.toUpperCase(); // ignore upper and lowercase
                      const nameB = b.label?.toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                    
                      // names must be equal
                      return 0;
                    })
            })
            if(callback){
              callback(arr);
            }
            
          } else {
            if(callback){
              callback([]);
            }
          }
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
    });
  }


  callManpowerDriverReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: true,
      // require_worker_only: true,
      light_data: true
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */

  callManpowerReadApi = (search = '', callback = null) => {
    const { accessToken } = this.context;

    let param = {
      // page: 1,
      // take: apiUtil.getDefaultPageSize(),
      disabled: false,
      is_active: true,
      search: search ? search.trim() : search,
      is_truck_driver: false,
      require_worker_only: true,
    };

    api.read('workers', param, accessToken)
    .then((result) => {
      apiUtil.parseResult(result, (data) => {
        let result = (data.result && data.result.length > 0) ? data.result : [];

        let arr = [];
        if(result && result.length > 0){
          arr = result.map((item, i) => {
            let value = item.id;
            let label = ((item.first_name && item.first_name !== '') ? item.first_name : '') + ' ' + ((item.last_name && item.last_name !== '') ? item.last_name : '');

            if(arr.findIndex(x => x.value === value) === -1){
              return {
                value: value,
                label: label,
                item: item
              }
            } else {
              return null;
            }
          });
  
          if(callback){
            callback(arr);
          }
        } else {
          if(callback){
            callback([]);
          }
        }
      }, (error, type) => {
        if(callback){
          callback([]);
        }
      });
    });
  }
  /* END WORKER API */

  callDetailsApi = (id = '', callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.read('orders/' + id, null, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback((data && data.result) ? data.result : null, (data && data.sorted_order_details) ? data.sorted_order_details : []);
              }
            });
          }, (error, type) => {
            apiUtil.toast(error, 'warning', 'error'); 
            this.setState({
              isLoading: false,
            });
          });
      });
    });
  }

  /* EXPORT API */
  callAllocationGroup = (callback = null) => {
    const { accessToken } = this.context;

    this.setState({
        // isLoading: true
    }, () => {

      let param = {
        step: 1,
        sort: "latest",
        light_data: true,
      };

      ldsApi.read('allocation_groups', param, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result && data.result.length > 0) ? data.result : [];
  
          this.setState({
            allocation_groups: result
          })
        }, (error, type) => {
          if(callback){
            callback([]);
          }
        });
      });
    });
  }
  
  
  callAllocationCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;
      let exit_name = this.state.allocation_group_name.filter((dd)=> dd.group_name == row.group_name);
      if(exit_name.length > 0) {
        apiUtil.toast('Group Name already exist!', 'check_circle');
      } else {
        this.setState({
          isLoading: true
        }, () => {
          ldsApi.create('allocation_groups', row, accessToken)
            .then((result) => {
              apiUtil.parseResult(result, (data) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback(data);
                  }
                });
              }, (error, type) => {
                this.setState({
                  isLoading: false,
                }, () => {
                  if(callback){
                    callback();
                  }
                });
              });
          });
        });
      }
  }


  callAllocationUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('allocation_groups/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback();
              }
            });
          });
      });
    });
  }
 


  /* TABLE */
  customTable = (orders, index) => {
    const { isCustomerRequired } = this.context;

    if(!this.state.isLoading) {
      return <Table 
      containerRef={this.containerRef}
      ref={this.refTable}
      className={'job-table-list' }  
      isLoading={this.state.isLoading}
      isDraggable={true}
      grouping={false}
      limit={20}
      type={'allocation'}
      total={orders.length}
      data={orders}
      defaultExpanded={true}
      selection={true}
      onPageChange={(page,limit) => {
      }}
      handleRowClick={(e, row)=> {
        e.stopPropagation();
        if(this.isCollectionFailed(row) == false) {
          if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
            this.setState({
              openPreviewDialog: true,
              openPreviewDialogItem: row.id,
            });
          }
        }
      }}
      onSelectionChange={(rows, column)=>{
        let data = this.state.data;
        data[index]["is_selected"] = true;
        data[index]["orders"].map((obj) => {
          if(rows.filter((o) => o.order_number == obj.order_number).length > 0) {
            obj.is_selected = true
          } else {
            obj.is_selected = false    
          }          
        });
        this.setState({data})

        if (!(rows.length > 0)) {
          let data = this.state.data;
          data[index]["is_selected"] = false;
          data[index]["orders"].map((obj) => {
            obj.is_selected = false;     
          });
          this.setState({data})
        }
        this.rowData = rows;
      }}
      onDragStart= {(e, rows)=>{
        if (rows != null && rows.job_type != 'Collection') {
          this.handleDragStart(e);
          this.rowData = rows;
        }
      }}
      // Toolbar={this.customToolbar}
      // Row={this.customRow}
      columns={[
        { 
          title: "Tracker Number",
          field: "order_number",
          cellStyle: {
             minWidth: 160,
             maxWidth: 160,
          },
          hidden: this.state.hiddenColumns.includes('delivery_number'),
          render: (row, type) => {
            if(type === 'row'){
              return  (row && row.order_number && row.order_number !== '') ?  
              <span 
              className={this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details') && this.isCollectionFailed(row) == false ? "linkable-place" : ""}
              style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}  
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                if(this.isCollectionFailed(row) == false) {
                  if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
                      this.setState({
                        openPreviewDialog: true,
                        openPreviewDialogItem: row.id,
                      });
                  }
                }
              }}> {row.order_number} </span>: '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Trip Date",
          field: "drop_off_date",
          cellStyle: {
             minWidth: 120,
            maxWidth: 120,
          },
          hidden: this.state.hiddenColumns.includes('date'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_date && row.drop_off_date !== '') ? 
              <span style={{ width:100, overflowWrap: 'break-word', display:'inline-block'}}>{moment(row.drop_off_date).format(apiUtil.getImportDateFormat()) }</span> : '';
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'DO')) ? [{ 
          title: "D.O Number", 
          field: "do_number",
          cellStyle: {
            minWidth: 160,
            maxWidth: 160,
         },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('do_number'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.do_number && row.do_number !== '') ? <span style={{ width:150, overflowWrap: 'break-word', display:'inline-block'}}>{row.do_number.replace(/\s/g, '\u00A0')}</span> : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Job Type", 
          field: "job_type",
          cellStyle: {
             minWidth: 120,
            maxWidth: 120,
          }, 
          sorting: false,
          hidden: this.state.hiddenColumns.includes('delivery_type'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.job_type && row.job_type !== '') ? <span style={{ width:110, overflowWrap: 'break-word', display:'inline-block'}}>{row.job_type == "Collection" ? 'Pick Up' : 'Delivery'}</span> : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Project", 
          field: "is_project",
          cellStyle: {
            minWidth: 120,
            maxWidth: 120,
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('is_project'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.is_project !== null) ? `${row.is_project == true ? "yes" : "No"}` : "No";
            } else {
              return row;
            }
          }
        },
        { 
          title: "Category", 
          field: "period_of_delivery",
          cellStyle: {
             minWidth: 120,
            maxWidth: 120,
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('period_of_delivery'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.period_of_delivery !== null) ? `${row.period_of_delivery}` : "";
            } else {
              return row;
            }
          }
        },
        { 
          title: "Pick Up Ref (D.O)",
          field: "collection_reference",
          cellStyle: {
            minWidth: 160,
            maxWidth: 160,
         }, 
          sorting: false,
          hidden: this.state.hiddenColumns.includes('collection_reference'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.collection_reference && row.collection_reference !== '') ? <span style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}>{row.collection_reference}</span> : '';
            } else {
              return row;
            }
          }
        },
        // { 
        //   title: "Ordered By", 
        //   field: "uploader",
        //   cellStyle: {
        //      minWidth: 150,
        //     maxWidth: 150,
        //   },
        //   customSort: (a,b) => (a.uploader && a.uploader.first_name? a.uploader.first_name : '').localeCompare(b.uploader &&  b.uploader.first_name? b.uploader.first_name : ''),
        //   hidden: this.state.hiddenColumns.includes('order_by'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       if(row.vendor_id && row.vendor_id == this.state.user.owner_info.application_company_id) {
        //         return (row && row.uploader && (row.uploader.application_company && row.uploader.application_company.company_name !== '')) ? <span style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}>{row.uploader.application_company.company_name}</span> : '';
        //       } else {
        //         return (row && row.uploader && (row.uploader.first_name !== '' || row.uploader.last_name !== '')) ? <span style={{ width:140, overflowWrap: 'break-word', display:'inline-block'}}>{(row.uploader.first_name? row.uploader.first_name: '') + ' ' + (row.uploader.last_name? row.uploader.last_name: '')}</span> : '';
        //       }
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        { 
          title: "Order Status",
          field: "order_status",
          cellStyle: {
            minWidth: 280,
            maxWidth: 280,    
          },
          sorting: false,
          customSort: (a,b) =>(a.order_status.status == "Not Assigned" ? "Pending" : a.order_status.status == "Assigned Pending" ? "On Hold": a.order_status.status).toString().toLowerCase().trim().localeCompare((b.order_status.status == "Not Assigned" ? "Pending" : b.order_status.status == "Assigned Pending" ? "On Hold": b.order_status.status).toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('order_status'),
          render: (row, type) => {
            let item = [];
            if(type === 'row'){
              item = this.state.tableStatus
              return <Box>
                    <Box>
                    {(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Status')) && (!row.vendor_id || row.vendor_id == this.state.user.owner_info.application_company_id) && this.isCollectionFailed(row) == false ?
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold"+'   ' :  row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending"+'   ': row.order_status.status == "Assign Pending" ? "On Hold"+'   ' : row.order_status.status+'   ' : ''}
                      rightIcon={<ArrowDownIcon />}
                      items={item}
                      onClick={(item) => {
                        if(row.drop_off_worker_id && row.drop_off_worker_id > 0 || item.text.toString().toLowerCase().trim() === 'cancelled' || item.text.toString().toLowerCase().trim() === 'pending' || item.text.toString().toLowerCase().trim() === 'on hold' || item.text.toString().toLowerCase().trim() === 'self collect'){
                          if((item.text.toString().toLowerCase().trim() === 'on hold' || item.text.toString().toLowerCase().trim() === 'self collect') && row.drop_off_worker_id) {
                            apiUtil.toast("Only Pending order(s) can be edited", "warning","error");
                            return;
                          }
                          let data = {
                            data: [
                              {
                                id: [ row.id ],
                                job_steps: (row.job_steps && row.job_steps.length > 0) ? row.job_steps.map(step => {
                                  step.job_step_id = step.id;
                                  if (item.text.toString().toLowerCase().trim() === "completed") {
                                    step.job_step_status_id = 3;
                                  } else {
                                    step.job_step_status_id = 1;
                                  }
                                  return step
                                }): row.job_steps,
                                order_status_id: item.id,
                                send_notification_to_customer: true,
                                drop_off_time_end: (item.text.toString().toLowerCase().trim() === "completed" || item.text.toString().toLowerCase().trim() === "failed") ? moment().format("h:mm a") : null,
                              }
                            ]
                          };

                          if(item.text.toString().toLowerCase().trim() === 'pending'){
                            data.data[0]['drop_off_worker_id'] = null;
                            data.data[0]['extra_worker_ids'] = null;
                            if (row.vendor_id == this.state.user.owner_info.application_company_id) {
                              data.data[0]['vendor_id'] = row.vendor_id;
                              let orderItem = apiUtil.customFilter(this.state.status, 'status', 'assigned to vendor')
                              data.data[0]['order_status_id'] = orderItem.id;
                            } else {
                              data.data[0]['vendor_id'] = null;
                            }
                          }

                          if(item.text.toString().toLowerCase().trim() === 'assigned to vendor' && row.drop_off_worker_id) {
                            apiUtil.toast("Order is already In Progress", "check_circle")
                            return;
                          }

                          this.callUpdateStatusApi(data, (newRow) => {
                            if(newRow){
                              let newData = apiUtil.updateRow(this.state.data, newRow, 'id');
                              this.callReadApi();
                              // this.setState({
                              //   data: newData,
                              // });
                            }
                          });
                        } else {
                          apiUtil.toast('You must first assign a driver!', 'warning', 'error'); 
                        }
                      }}
                    /> : 
                    <DropdownMenu 
                      isShouldEnable={true}
                      className={'status-btn ' + apiUtil.statusToClassName((row.order_status && row.order_status.status && row.order_status.status !== '') ?  row.order_status.status == "Not Assigned" ? "Pending" :  row.order_status.status == "Assign Pending" ? "On Hold"+'   ' :  row.order_status.status : '')}
                      text={(row && row.order_status && row.order_status.status) ? row.order_status.status == "Not Assigned" ? "Pending"+'   ': row.order_status.status == "Assign Pending" ? "On Hold"+'   ' : row.order_status.status+'   ' : ''}
                      // rightIcon={<ArrowDownIcon />}
                      // items={this.state.tableStatus}
                    />
                     }        
                  </Box>
              </Box>;
          } else {
              return row;
            }
          }
        },
        // { 
        //   title: "POD", 
        //   field: "POD",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   hidden: this.state.hiddenColumns.includes('POD'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
        //       if(jobStep) {
        //       let signature_img = row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == true) : []
        //       let proof_img =  row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0 && jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 0 ? jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.filter(dd => dd.is_signature == false) : []
             
        //       return <div className='d-flex align-items-center justify-content-center'>
        //               {proof_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
        //               <img src={proof_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
        //               <span>
        //                 <img src={proof_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
        //               </span> 
        //               {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
        //                 <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
        //                 onClick={(e) => {
        //                   e.stopPropagation();
        //                   e.preventDefault();
        //                   if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
        //                     if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
        //                       this.setState({
        //                         openPreviewDialog: true,
        //                         openPreviewDialogItem: row.id,
        //                       });
        //                     }
        //                   }
        //                 }}/> : ''} </div> : signature_img.length ? <div className="image-preview d-flex align-items-center justify-content-between w-100">
        //                   <img src={signature_img[0]['image_url']} className="w-50" style={{margin: "auto"}}/>
        //                 <span>
        //                   <img src={signature_img[0]['image_url']} className="hovered-image" style={{margin: "auto"}}/>
        //                 </span>
        //                 {jobStep.order_attempts[jobStep.order_attempts.length - 1].order_attempts_images.length > 1 ? 
        //                   <FontAwesomeIcon icon={faPlusCircle} className=" fs-5 svg-primary"
        //                   onClick={(e) => {
        //                     e.stopPropagation();
        //                     e.preventDefault();
        //                     if((this.isCollectionFailed(row) == false || (this.isCollectionFailed(row) == true && row.job_type == "Collection"))) {
        //                       if (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')) {
        //                         this.setState({
        //                           openPreviewDialog: true,
        //                           openPreviewDialogItem: row.id,
        //                         });
        //                       }
        //                     }
        //                   }}/> : ""}
        //           </div> : '' }
        //       </div>
        //       }
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Reason for Failure", 
        //   field: "reason_for_failure",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => (a.job_steps.length > 0 && a.job_steps[0].order_attempts.length > 0 ? a.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim().localeCompare((b.job_steps.length > 0 && b.job_steps[0].order_attempts.length > 0 ? b.job_steps[0].order_attempts[0].reason: '').toString().toLowerCase().trim()),
        //   hidden: this.state.hiddenColumns.includes('reason_for_failure'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let jobStep = row.job_steps && row.job_steps[row.job_steps.length - 1]
        //       if(jobStep) {
        //       let failed_reason = row.job_steps.length > 0 && jobStep.order_attempts.length > 0  ? jobStep.order_attempts[jobStep.order_attempts.length - 1].reason : ''
        //       return row && row.order_status && row.order_status.status == "Failed" ? failed_reason : '';
        //       } 
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        ... (this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Address')) ?[{ 
          title: "Delivery Address", 
          field: "drop_off_address",
          cellStyle: {
            minWidth: 180,
            maxWidth: 180
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('delivery_address'),
          render: (row, type) => {
            if(type === 'row'){
              // return (row && row.drop_off_address && row.drop_off_address !== '') ? HtmlParser(row.drop_off_address) : '';
              return (row && row.drop_off_address && row.drop_off_address !== '') ?<span style={{ width:170, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_address }</span>  : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Postal Code", 
          field: "drop_off_postal_code",
          cellStyle: {
             minWidth: 120,
            maxWidth: 120,
          },
          sorting: false,
          customSort: (a,b) => Number(a.drop_off_postal_code ? a.drop_off_postal_code: '') - Number(b.drop_off_postal_code ? b.drop_off_postal_code: ''),
          hidden: this.state.hiddenColumns.includes('postal_code'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.drop_off_postal_code && row.drop_off_postal_code !== '') ? HtmlParser(row.drop_off_postal_code) : '';
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Delivery Time')) ?[{ 
          title: "Between", 
          field: "drop_off_time_planned",
          cellStyle: {
            minWidth: 120,
            maxWidth: 120,
         },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[0] : '';
            } else {
              return row;
            }
          }
        }, 
        { 
          title: "And", 
          field: "drop_off_time_planned",
          cellStyle: {
            minWidth: 120,
            maxWidth: 120,
         },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('time_slot'),
          render: (row, type) => {
            if(type === 'row'){
              let drop_from_time = row && row.drop_off_time_planned && row.drop_off_time_planned !== '' ? row.drop_off_time_planned.split("-") : [];
              return drop_from_time.length > 0 && row.drop_off_time_planned != '9:00 - 18:00' ? drop_from_time[1] : '';
            } else {
              return row;
            }
          }
        }] : [],
        // ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver') ||
        //     this.state.currentRole == 'Ops Admin' ||
        //     this.state.currentRole == 'Finance Admin' ||
        //     this.state.currentRole == 'BD Admin') ? [{ 
        //   title: "Driver-Partner", 
        //   field: "driver_partner",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => (a.drop_off_worker ? a.drop_off_worker.first_name : '').toString().toLowerCase().trim().localeCompare((b.drop_off_worker ? b.drop_off_worker.first_name : '').toString().toLowerCase().trim()),
        //   hidden: this.state.hiddenColumns.includes('driver_partner'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       if(row.drop_off_worker_id && row.drop_off_worker_id > 0 && row.drop_off_worker){
                
        //         row.workerName = row.drop_off_worker.first_name +" "+ row.drop_off_worker.last_name;
        //       }
        //       if(row.vendor_id && row.vendor_id != null){
        //         row.vendorName = ( row.vendor && row.vendor.company_name != null ? row.vendor.company_name : "") ;
        //       }
            
        //       return row.vendor_id ? row.vendor_id != this.state.user.owner_info.application_company_id ? row.vendorName : row.workerName : row.workerName  
        //     } else {
        //       return row;
        //     }
        //   }
        // }] : [],
        // ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Man Power')||
        //     this.state.currentRole == 'Ops Admin' ||
        //     this.state.currentRole == 'Finance Admin' ||
        //     this.state.currentRole == 'BD Admin') ? [{ 
        //   title: "Driver-Assistant", 
        //   field: "extra_workers[0].first_name",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => (a.extra_workers && a.extra_workers.length > 0 && a.extra_workers[0].first_name ? a.extra_workers[0].first_name : '').toString().toLowerCase().trim().localeCompare((b.extra_workers && b.extra_workers.length > 0 && b.extra_workers[0].first_name ? b.extra_workers[0].first_name  : '').toString().toLowerCase().trim()),
        //   hidden: this.state.hiddenColumns.includes('driver_partner'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let label = '';
        //       let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;

        //       if(manpowerName){
        //         label = ((manpowerName.first_name && manpowerName.first_name !== '') ? manpowerName.first_name : '') + ' ' + ((manpowerName.last_name && manpowerName.last_name !== '') ? manpowerName.last_name : '');
        //       }

        //       row.manpowerName = label;
        //       return row.manpowerName;
              
        //     } else {
        //       return row;
        //     }
        //   }
        // }] : [],
        // ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Company')) ? [{ 
        //   title: "Client Company", 
        //   field: "recipent_company",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   hidden: this.state.hiddenColumns.includes('recipent_company'),
        //   customSort: (a,b) => (a.customer ? a.customer.company_name : '').toString().toLowerCase().trim().localeCompare((b.customer ? b.customer.company_name : '').toString().toLowerCase().trim()),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.customer && row.customer.company_name !== '') ? HtmlParser(row.customer.company_name) : ''} </span>;
        //       // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // }] : [],
        // { 
        //   title: "Department", 
        //   field: "department",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   hidden: this.state.hiddenColumns.includes('department'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.department && row.department.name ) ? HtmlParser(row.department.name) : ''}</span>;
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        { 
          title: "Recipient's Name", 
          field: "drop_off_contact_name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('recipient_name'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_contact_name ) ? HtmlParser(row.drop_off_contact_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Company", 
          field: "drop_off_name",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('company'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_name ) ? HtmlParser(row.drop_off_name) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Contact No.", 
          field: "drop_off_contact_no",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('recipient_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_contact_no) ? HtmlParser(row.drop_off_contact_no) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Alternative Contact", 
          field: "drop_off_alternate_contact",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('alternate_contact'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:130, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_alternate_contact) ? HtmlParser(row.drop_off_alternate_contact) : ''} </span>;
            } else {
              return row;
            }
          }
        },
        { 
          title: "Recipient Email", 
          field: "notification_email",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('recipient_email'),
          render: (row, type) => {
            if(type === 'row'){
              return <span style={{ width:120, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.notification_email ) ? HtmlParser(row.notification_email) : ''}</span>;
            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver')) ?[{ 
          title: "Item Description", 
          field: "drop_off_description",
          cellStyle: {
            minWidth: 200,
            maxWidth: 200
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('item_description'),
          render: (row, type) => {
            if(type === 'row'){
              return <span className='none-backgroud-color' style={{ width:180, overflowWrap: 'break-word', display:'inline-block'}}>{(row && row.drop_off_description && row.drop_off_description !== '') ? HtmlParser(row.drop_off_description) : ''}</span>;
              // return (row && row.drop_off_description && row.drop_off_description !== '') ?<span style={{ width:350, overflowWrap: 'break-word', display:'inline-block'}}> {row.drop_off_description }</span>  : '';
            } else {
              return row;
            }
          }
        }] : [],
        // { 
        //   title: "XS Packages", 
        //   field: "xs_packages",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 1).length > 0 ? a.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 1).length > 0 ? b.order_packages.filter(dd => dd.package_id == 1)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('xs_packages'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "S Packages", 
        //   field: "s_packages",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 2).length > 0 ? a.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: '') - (b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 2).length > 0 ? b.order_packages.filter(dd => dd.package_id == 2)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('s_packages'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "M Packages", 
        //   field: "m_packages",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 3).length > 0 ? a.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 3).length > 0 ? b.order_packages.filter(dd => dd.package_id == 3)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('m_packages'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "L Packages", 
        //   field: "l_packages",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 4).length > 0 ? a.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 4).length > 0 ? b.order_packages.filter(dd => dd.package_id == 4)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('l_packages'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Oversized Package", 
        //   field: "oversized_packages",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 6).length > 0 ? a.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 6).length > 0 ? b.order_packages.filter(dd => dd.package_id == 6)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('oversized_packages'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Document", 
        //   field: "document",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).document && !isNaN(JSON.parse(a.extra_data).document) ? JSON.parse(a.extra_data).document : '') - Number(b.extra_data && JSON.parse(b.extra_data).document && !isNaN(JSON.parse(b.extra_data).document) ? JSON.parse(b.extra_data).document : ''),
        //   hidden: this.state.hiddenColumns.includes('document'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.document) ? extra_data.document : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Boxes", 
        //   field: "boxes",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).boxes && !isNaN(JSON.parse(a.extra_data).boxes) ? JSON.parse(a.extra_data).boxes : '') - Number(b.extra_data && JSON.parse(b.extra_data).boxes && !isNaN(JSON.parse(b.extra_data).boxes) ? JSON.parse(b.extra_data).boxes : ''),
        //   hidden: this.state.hiddenColumns.includes('boxes'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.boxes) ? extra_data.boxes : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Cartons", 
        //   field: "cartons",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.extra_data && JSON.parse(a.extra_data).cartons && !isNaN(JSON.parse(a.extra_data).cartons) ? JSON.parse(a.extra_data).cartons : '') - Number(b.extra_data && JSON.parse(b.extra_data).cartons && !isNaN(JSON.parse(b.extra_data).cartons) ? JSON.parse(b.extra_data).cartons : ''),
        //   hidden: this.state.hiddenColumns.includes('cartons'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let extra_data = JSON.parse(row.extra_data)
        //       return (extra_data && extra_data.cartons) ? extra_data.cartons : '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        // { 
        //   title: "Pallets", 
        //   field: "pallets",
        //   cellStyle: {
        //     minWidth: 150,
        //     maxWidth: 150
        //   },
        //   customSort: (a,b) => Number(a.order_packages.length > 0 && a.order_packages.filter(dd => dd.package_id == 5).length > 0 ? a.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: '') - Number(b.order_packages.length > 0 && b.order_packages.filter(dd => dd.package_id == 5).length > 0 ? b.order_packages.filter(dd => dd.package_id == 5)[0].package_quantity: ''),
        //   hidden: this.state.hiddenColumns.includes('pallets'),
        //   render: (row, type) => {
        //     if(type === 'row'){
        //       let pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 5) : []
        //       return (pack.length > 0) ? pack[0].package_quantity: '';
        //     } else {
        //       return row;
        //     }
        //   }
        // },
        { 
          title: "Depalletization Request", 
          field: "depalletization_request",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => (a.package_info && JSON.parse(a.package_info).depalletization_request ? JSON.parse(a.package_info).depalletization_request : '').toString().toLowerCase().trim().localeCompare((b.package_info && JSON.parse(b.package_info).depalletization_request ? JSON.parse(b.package_info).depalletization_request : '').toString().toLowerCase().trim()),
          hidden: this.state.hiddenColumns.includes('depalletization_request'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.depalletization_request) ? package_info.depalletization_request  : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Size in cm(L + W + H)", 
          field: "size_lwh",
          cellStyle: {
            minWidth: 170,
            maxWidth: 170
          },
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).size_l_w_h  && !isNaN(JSON.parse(a.package_info).size_l_w_h) ? JSON.parse(a.package_info).size_l_w_h : '') - Number(b.package_info && JSON.parse(b.package_info).size_l_w_h && !isNaN(JSON.parse(b.package_info).size_l_w_h)? JSON.parse(b.package_info).size_l_w_h : ''),
          hidden: this.state.hiddenColumns.includes('size_lwh'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.size_l_w_h) ? package_info.size_l_w_h : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Weight (KG)", 
          field: "weight",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).weight  && !isNaN(JSON.parse(a.package_info).weight) ? JSON.parse(a.package_info).weight : '') - Number(b.package_info && JSON.parse(b.package_info).weight && !isNaN(JSON.parse(b.package_info).weight)? JSON.parse(b.package_info).weight : ''),
          hidden: this.state.hiddenColumns.includes('weight'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.weight) ? package_info.weight : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Waiting Time", 
          field: "waiting_time",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).waiting_time  && !isNaN(JSON.parse(a.package_info).waiting_time) ? JSON.parse(a.package_info).waiting_time : '') - Number(b.package_info && JSON.parse(b.package_info).waiting_time && !isNaN(JSON.parse(b.package_info).waiting_time)? JSON.parse(b.package_info).waiting_time : ''),
          hidden: this.state.hiddenColumns.includes('waiting_time'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.waiting_time) ? package_info.waiting_time : '';
             } else {
              return row;
            }
          }
        },
        { 
          title: "Additional Manpower", 
          field: "no_of_movers",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => Number(a.package_info &&  JSON.parse(a.package_info).no_of_movers  && !isNaN(JSON.parse(a.package_info).no_of_movers) ? JSON.parse(a.package_info).no_of_movers : '') - Number(b.package_info && JSON.parse(b.package_info).no_of_movers && !isNaN(JSON.parse(b.package_info).no_of_movers)? JSON.parse(b.package_info).no_of_movers : ''),
          hidden: this.state.hiddenColumns.includes('no_of_movers'),
          render: (row, type) => {
            if(type === 'row'){
              let package_info = JSON.parse(row.package_info)
              return (package_info && package_info.no_of_movers) ? package_info.no_of_movers : '';
            } else {
              return row;
            }
          }
        },
        { 
          title: "Price", 
          field: "amount",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => Number(a.amount ? a.amount : '') - Number(b.amount ? b.amount : ''),
          hidden: this.state.hiddenColumns.includes('price'),
          render: (row, type) => {
            if(type === 'row'){
              if (row.vendor_id == this.state.user.owner_info.application_company_id) {
                return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

              } 
              return (row && row.amount && row.amount !== '') ? parseFloat(row.amount).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
              
            } else {
              return row;
            }
          }
        },
        { 
          title: "Vendor Payout", 
          field: "vendor_payout",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          customSort: (a,b) => Number(a.vendor_payout ? a.vendor_payout : '') - Number(b.vendor_payout ? b.vendor_payout : ''),
          hidden: this.state.hiddenColumns.includes('vendor_payout'),
          render: (row, type) => {
            if(type === 'row'){
              if ( row.vendor_id == this.state.user.owner_info.application_company_id || !row.vendor_id) {
                  return '-'
              } 
              return (row && row.vendor_payout && row.vendor_payout !== '') ? parseFloat(row.vendor_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';

            } else {
              return row;
            }
          }
        },
        ...(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Driver Payout')) ?[{ 
          title: "Driver Payout", 
          field: "driver_payout",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('driver_payout'),
          customSort: (a,b) => Number(a.driver_payout ? a.driver_payout : '') - Number(b.driver_payout ? b.driver_payout : ''),
          render: (row, type) => {
            if(type === 'row'){
              if((row.vendor_id && row.vendor_id != this.state.user.owner_info.application_company_id) || !row.drop_off_worker_id ) {
                return '-'
              }
              return (row && row.driver_payout && row.driver_payout !== '') ? parseFloat(row.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '';
            } else {
              return row;
            }
          }
        }] : [],
        { 
          title: "Notes to Driver-Partner", 
          field: "driver_notes",
          cellStyle: {
            minWidth: 150,
            maxWidth: 150
          },
          sorting: false,
          hidden: this.state.hiddenColumns.includes('notes_to_driver'),
          render: (row, type) => {
            if(type === 'row'){
              return (row && row.driver_notes && row.driver_notes !== '') ? HtmlParser(row.driver_notes) : '';
             } else {
              return row;
            }
          }
        }
      ]}
    />

    }else {
      return <Box display={'flex'} alignItems={'center'} justifyContent={'center'} height={'75vh'}>
        <CircularProgress size={35} />
      </Box>
    }

    
  }

  handleTabChange = (event, newValue) => {
    this.setState({
        tabValue: newValue
    })
  }

  callCreateApi = (row, callback = null) => {
    const { accessToken } = this.context;
    this.setState({
      isLoading: true,
    }, ()=> {
      ldsApi.create('duplicate/orders/multiple', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          if(callback){
            callback(data);
          }
        }, (error, type) => {
          this.setState({
            isLoading: false,
          }, () => {
            apiUtil.toast(
              error,
              "check_circle"
            );
          });
        });
    });
    })

  }

  callUpdateApi = (id, row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('orders/' + id, row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            apiUtil.toast(
              "Successfully Updated",
              "check_circle"
            )
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle"
            )
            this.setState({
              isLoading: false,
            }, () => {
            });
          });
      });
    });
  }

  callUpdateDoApi = (row, callback = null) => {
    const { accessToken } = this.context;

    this.setState({
      isLoading: true
    }, () => {
      ldsApi.update('/allocation/do_number', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            apiUtil.toast(
              "Successfully Updated",
              "check_circle"
            )
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(data);
              }
            });
          }, (error, type) => {
            apiUtil.toast(
              error,
              "check_circle"
            )
            this.setState({
              isLoading: false,
            }, () => {
            });
          });
      });
    });
  }
  /* DIALOG */
  customDialog = () => {
    return <CustomDialog 
      open={this.state.openDialog}
      title={'Delete'}
      onClose={() => {
        this.setState({
          openDialog: false,
          openDialogItem: null,
        });
      }}
      onOk={() => {
        let row = this.state.openDialogItem;
        this.callDeleteApi(row, () => {
          this.setState({
            openDialog: false,
            openDialogItem: null,
          }, () => {
            this.loadStatusApi();
            this.callReadApi(this.refTableSearch.current.state.value);
            apiUtil.toast('Successfully Deleted', 'check_circle');
          });
        });
      }}
    >
      <Box>Are you sure you want to delete?</Box>
    </CustomDialog>
  }

    /* DIALOG */
    customDoDialog = () => {
      return <CustomDialog 
        open={this.state.openDoDialog}
        title={'Duplicate Order'}
        onClose={() => {
          this.setState({
            openDoDialog: false,
            DoDialogOrder: null,
          });
          this.DoDialogOrder = null;
        }}
        onOk={() => {
          let row = this.DoDialogOrder;
          if ( row['0'].do_number != null) {
            row['0'].do_number = row['0'].do_number.trim()
            this.callCreateApi(row, () => {
              this.setState({
                openDoDialog: false,
                DoDialogOrder: null,
              }, () => {
                this.DoDialogOrder = null;
                this.callInitApis();
              });
            });
        }
        }}
      >
        <Box className="do_warning text-center"><FontAwesomeIcon icon={faWarning} style={{color: "#ff9966"}}/> Please unique D.O Number for Duplicate Pick Up Order</Box>
        {this.DoDialogOrder && this.DoDialogOrder.orders && this.DoDialogOrder.orders[0].do_number && <Box className="do_warning my-2 p-2 text-center"><FontAwesomeIcon icon={faWarning} style={{color: "#ff9966"}}/> D.O number can't be blank</Box>}
        <input className="form-control" placeholder="Eg. ABCD001" onChange={(e)=>{
            let doDialogOrder = this.DoDialogOrder;
            doDialogOrder['0'].do_number = e.target.value;
            ['id','order_number','order_status','extra_workers','uploader','reference_no'].forEach(e => delete doDialogOrder['0'][e]);
            doDialogOrder['0'].drop_off_date = moment(doDialogOrder['0'].drop_off_date).format("YYYY-MM-DD");
            doDialogOrder['0'].collection_reference = null;
            this.DoDialogOrder = doDialogOrder
            // this.setState({toUpdate:doDialogOrder})
        }}/>
      </CustomDialog>
    }

    customWarnDialog = () => {
      return <CustomDialog 
        open={this.state.openWarnDialog}
        title={'Warning'}
        onClose={() => {
          this.setState({
            openWarnDialog: false,
          });
        }}
        onOk={() => {
          this.props.history.push(this.state.route);
        }}
      >
        <Box className="do_warning text-center">
          <FontAwesomeIcon icon={faWarning} style={{color: "#ff9966"}} className="fs-3"/>
          <div className="fs-6 font-medium p-2 pb-0" style={{color: "#ff9966"}}>Pick Up without Delivery order is exist!!</div>
          <div className="fs-6 font-medium p-2 pb-0" style={{color: "#ff9966"}}>Are you sure?</div>
        </Box>
      </CustomDialog>
    }
  
 
  Updatedtoast = () => {
    apiUtil.toast('Successfully Updated', 'check_circle');
  }



  previewDialog = () => {
    if(this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Details')){
    return <CustomDialog 
      open={this.state.openPreviewDialog}
      title={'Order Details'}
      maxWidth={'lg'}
      padding={'0'}
      hideButtons={true}
      onClose={() => {
        this.setState({
          openPreviewDialog: false,
          openPreviewDialogItem: null,
        });
      }}
    >
      <JobPreviewDialog
        id={this.state.openPreviewDialogItem}
        onClose={() => {
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onSave={(data) => {
          if(data) {
            apiUtil.toast('Successfully Updated', 'check_circle');
            this.callInitApis()
          }
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
      />
      <OrderDetailForm 
        orderId={this.state.openPreviewDialogItem}
        onSave={() => {
          this.callReadApi();
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });
        }}
        onClose={()=>{
          this.setState({
            openPreviewDialog: false,
            openPreviewDialogItem: null,
          });        
        }}
    /> 
    </CustomDialog>
    }
  }

  autoAssignDialog = () => {
    return  this.setState({
      autoAssign: true
    })
  }




  /* STEPPER */
  setStepper = () => {
    return <>
      <CustomGuideStepper
        activeStep={3}
        steps={[
          {
            label: 'Create Customer',
          },
          {
            label: 'Create Vehicle',
          },
          {
            label: 'Create Driver',
          },
          {
            label: 'Create Job',
          },
        ]}
        onClick={(index) => {
          if(index === 0){
            this.props.history.push('/customers');
          } else if(index === 1){
            this.props.history.push('/resources/vehicle');
          } else if(index === 2){
            this.props.history.push('/resources/transport-operator');
          } else if(index === 3){
            // this.props.history.push('/job-list');
          }
        }}
      />
      <Box pb={2} textAlign={'center'}>This is the Job list page.Let's learn some basics about this page.</Box>
      <Box textAlign={'center'}>
        <CustomButton 
          color={'primary'}
          href={'/'}
          onClick={(e) => {
              e.preventDefault();

              this.setState({
                openSkipDialog: true,
              });
          }}
        >
          Skip All
        </CustomButton>
      </Box>
    </>
  }
  /* END STEPPER */

  /* TOUR */
  setTour = () => {
    return <>
      <Tour
        steps={[
          {
            selector: '.select-dates-step',
            content: <Box>
              <h2>Select dates</h2>
              <p>Here is where you choose a range of dates to display in the job list</p>
            </Box>,
          },
          {
            selector: '.job-statuses-step',
            content: <Box>
              <h2>Job Statuses</h2>
              <p>This area is where you monitor all of your job progress in general. You can filter all job statuses by clicking on any of the statuses here.</p>
            </Box>,
          },
          {
            selector: '.job-listing-step',
            content: <Box>
              <h2>Job Listing</h2>
              <p>Like vehicles and drivers, here is where all your available jobs are are populated.You can edit the displayed columns and sort by a column.</p>
            </Box>,
          },
          {
            selector: '.new-job-step',
            stepInteraction: true,
            content: <Box>
              <h2>Create job</h2>
              <p>Now let's create a new job.</p>
            </Box>,
          },
        ]}
        isOpen={this.state.isTourOpen}
        rounded={10}
        // accentColor={'#64CCC9'}
        accentColor={'#007CDE'}
        showNumber={false}
        showNavigation={false}
        disableInteraction={true}
        // prevButton={<></>}
        // nextButton={<></>}
        lastStepNextButton={<></>}
        onRequestClose={() => {
          this.setState({
            isTourOpen: false,
          });
        }} />
    </>
  }
  /* END TOUR */

  /* SKIP DIALOG */
  skipDialog = () => {
    const { accessToken, setOnBoardingCustomer, setOnBoardingVehicle, setOnBoardingDriver, setOnBoardingJob } = this.context;

    return <CustomDialog 
      open={this.state.openSkipDialog}
      title={'End Tutorial'}
      btnCloseText={'No'}
      onClose={() => {
        this.setState({
          openSkipDialog: false,
        });
      }}
      onOk={() => {
        apiUtil.callUpdateAdminProfileApi(accessToken, (data) => {
          this.setState({
            openSkipDialog: false,
          }, () => {
            setOnBoardingCustomer(null);
            setOnBoardingVehicle(null);
            setOnBoardingDriver(null);
            setOnBoardingJob(null);

            apiUtil.toastOnBoarding('Done', 'check_circle');
            this.props.history.push('/');
          });
        });
      }}
    >
      <Box>Are you sure you want to end tutorial?</Box>
    </CustomDialog>
  }
  /* END SKIP DIALOG */

  /*START AUTO ASSIGN*/
  autoAssignApi = (callback = null) =>{
    const { accessToken } = this.context;

    let row = {};
    row.start_date = moment(this.state.startDate).format("YYYY-MM-DD");
    row.end_date = moment(this.state.endDate).format("YYYY-MM-DD");

    this.setState({
      isLoading: true
    }, () => {

      ldsApi.create('auto_assign/jobs', row, accessToken)
        .then((result) => {
          apiUtil.parseResult(result, (data) => {
            let result = (data.job_assigned && data.job_assigned.length > 0) ? data.job_assigned : null;

            if(result){
              let currentData = this.state.data;
              currentData.map((item, i)=>{
                 result.map((res, ii)=>{
                    if(item.id == res.id){
                      item.order_status_id = res.order_status_id;
                      item.order_status = res.order_status;

                      item.drop_off_worker_id = res.drop_off_worker_id;
                      item.drop_off_worker = res.drop_off_worker;
                      item.drop_off_worker_name = res.drop_off_worker_name;
                    }
                 }); 
              });

              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(result);
                }
              });
            } else {
              this.setState({
                isLoading: false,
              }, () => {
                if(callback){
                  callback(null);
                }
              });
            }
          }, (error, type) => {
            this.setState({
              isLoading: false,
            }, () => {
              if(callback){
                callback(null);
              }
            });
          });
      });
    });    
  }

  checkLatLong = (currentData=null, callback = null)=>{
    if(!currentData){ currentData = this.state.data; };

    let invalidLatLong = [];
    let finishCallback = [];
  
    this.setState({
      isLoading: true
    }, () => {

        currentData.filter(x => x.order_status_id == 679).map((v,k)=>{
             
            let row = {};
            row.address = v.drop_off_address; 
            this.generateLatLong(row, (data) => {
                if(data.success){
                  let isDiffLatLong = false;
                  if(v.drop_off_latitude != data.lat && v.drop_off_longitude != data.lng){
                    v.drop_off_latitude = data.lat.toString();
                    v.drop_off_longitude = data.lng.toString();
                    isDiffLatLong = true;
                  }
                  
                  let format = {};
                  format.data = [];

                  let template = {};
                  template.id = [v.id];
                  template.drop_off_latitude = v.drop_off_latitude;
                  template.drop_off_longitude = v.drop_off_longitude;
                  template.drop_off_address = v.drop_off_address;
                  format.data.push(template);

                  if(isDiffLatLong){
                    this.updateLatLong(format, (result) => {
                      if(!result){ invalidLatLong.push(v); }
                      finishCallback.push("true");

                      if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                        this.showInvalidAddress(invalidLatLong);
                      }
                    });
                  }else{
                    finishCallback.push("true");

                    if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                      this.showInvalidAddress(invalidLatLong);
                    }
                  }
                  
                }else{
                  invalidLatLong.push(v);
                  finishCallback.push("true");

                  if(finishCallback.length == currentData.filter(x => x.order_status_id == 679).length){
                    this.showInvalidAddress(invalidLatLong);
                  }
                }
            });
          
        });
    });  
  }

  showInvalidAddress = (invalidLatLong, callback = null)=>{
    if(invalidLatLong.length == 0){ 
      this.autoAssignApi();
    }else{
      this.setState({
                      isLoading: false, 
                      openDialogAutoAssign:true,
                      invalidAddress: invalidLatLong
                  });
    }
  }

  generateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('geo_functions/geocoder_address', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  updateLatLong = (row, callback = null)=>{
    const { accessToken } = this.context;
    ldsApi.create('orders/assign/order', row, accessToken)
      .then((result) => {
        apiUtil.parseResult(result, (data) => {
          let result = (data.result) ? data.result : null;
          if(result){            
            callback(result);
          }else{ 
            callback(null);   
          }
        }, (error, type) => {
          callback(null)
        });
    });
  }

  /*END AUTO ASSIGN*/


  requestSearch = (searchedVal) => {
    let filteredRows = [];
    let filter = [];
    filteredRows = this.state.data.filter((row) => {
      let jobStep = row.job_steps && row.job_steps[0];
      let manpowerName = (row.extra_workers && row.extra_workers.length > 0) ? row.extra_workers[0] : null;
      let xs_pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 1) : []
      let s_pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 2) : []
      let m_pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 3) : []
      let l_pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 4) : []
      let o_pack = row.order_packages ? row.order_packages.filter(dd => dd.package_id == 6) : []
      let extra_data = row.extra_data != 'undefined' ? JSON.parse(row.extra_data) : {}
      let package_info = row.package_info != 'undefined' ? JSON.parse(row.package_info) : {}

      if ((row.drop_off_date != null && row.drop_off_date.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.order_number != null && row.order_number.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) || 
          (row.do_number != null && row.do_number.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.job_type != null && row.job_type.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.uploader != null && row.uploader.first_name.toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.order_status != null && row.order_status.status.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()) ||
          (row.job_steps.length > 0 && jobStep.order_attempts && jobStep.order_attempts.length > 0  && jobStep.order_attempts[0].reason != null && jobStep.order_attempts[0].reason.toString().toLowerCase().includes(searchedVal.toString().toLowerCase()))) ||
          (row.drop_off_address != null  && row.drop_off_address.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.drop_off_postal_code != null && row.drop_off_postal_code.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.drop_off_time_planned != null && row.drop_off_time_planned.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.drop_off_worker && row.drop_off_worker.first_name != null && row.drop_off_worker.first_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (manpowerName && manpowerName.first_name != null && manpowerName.first_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.customer && row.customer.company_name != null && row.customer.company_name.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (xs_pack.length > 0 && xs_pack[0].package_quantity != null && xs_pack[0].package_quantity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (s_pack.length > 0 && s_pack[0].package_quantity != null && s_pack[0].package_quantity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (m_pack.length > 0 && m_pack[0].package_quantity != null && m_pack[0].package_quantity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (l_pack.length > 0 && l_pack[0].package_quantity != null && l_pack[0].package_quantity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (o_pack.length > 0 && o_pack[0].package_quantity != null && o_pack[0].package_quantity.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (extra_data && extra_data.document != null && extra_data.document.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (extra_data && extra_data.boxes != null && extra_data.boxes.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (extra_data && extra_data.cartons != null && extra_data.cartons.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (extra_data && extra_data.pallets != null  && extra_data.pallets.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.notification_email != null && row.notification_email.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.drop_off_description != null && row.drop_off_description.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (package_info && package_info.depalletization_request != null && package_info.depalletization_request.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (package_info && package_info.size_l_w_h != null && package_info.size_l_w_h.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (package_info && package_info.weight != null && package_info.weight.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (package_info && package_info.waiting_time != null && package_info.waiting_time.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (package_info && package_info.no_of_movers != null && package_info.no_of_movers.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.amount != null && row.amount.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.driver_payout != null && row.driver_payout.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) ||
          (row.driver_notes != null && row.driver_notes.toString().toLowerCase().includes(searchedVal.toString().toLowerCase())) 
      ) {
        return row
      } 
    });
    this.setState({
      filter_data: filteredRows
    })
  };


  handleChange = (val) => this.setState({value: val});
  customSearch = () => {
    return <div>
            <Row className="px-4">
            <Col>
                <Card className="overflow-hidden box-shadow-search">
                    <div className="row border-0 d-flex justify-content-start align-items-center max-block-lg">
                      <div className="col-xs-12 col-md-6 d-flex col-md-auto align-items-center justify-self-start me-auto">
                        <div className="row d-flex">
                            <div className="col-12 col-xs-12 col-lg-8">
                              <input placeholder="Enter Job Number, Company Name, etc" className="border-0 border-bottom p-2 m-4 max-height-input" 
                              value={this.state.searched}
                              onChange={(e)=> {
                                this.setState({
                                  searched : e.target.value
                              })
                              }}
                               onEnter={(e) => {
                                this.pages = 1
                                this.callReadApi()
                              }}
                              />
                              </div>
                              <div className="col-12 col-xs-12 col-lg-4 d-flex align-items-center">
                              <Button className="upload-style-search max-height-btn my-4"
                                onClick={(e) => {
                                  this.pages = 1
                                  this.callReadApi()
                                }}
                              ><FontAwesomeIcon icon={faMagnifyingGlass} /> Search</Button>
                                {(this.state.searched !== '' ) && <Box mb={1}>
                                  <IconButton
                                  onClick={e => {
                                    this.setState({
                                      searched: ''
                                    }, () =>{
                                      this.callReadApi()
                                    })
                                    
                                  }}
                                  ><span  data-tooltip="clear search" data-tooltip-conf="danger"><img src={Filterimg} width="25px" /></span></IconButton>
                                  </Box>
                                }
                              </div>
                              </div>
                              </div>
                              <div className="col-xs-12 col-md-6 d-flex col-md-auto align-items-center justify-self-end justify-content-end ms-auto">
                                <div className="row d-flex justify-content-end p-4">
                                   <Button className="delete-style max-xs-width-control-1019 m-2"
                                   disabled={this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete') ? false : true }
                                   onClick={e=>{  
                                   
                                     let selected_orders = this.state.data.filter(obj => obj.is_selected == true)
                                     if(selected_orders.length > 0) {
                                       this.setState({
                                         openBulkDeleteDialog : true,
                                       })
                                     }
                                   }}
                                   >Bulk Delete</Button>
                                </div>
                            </div>
                          
                        </div>
                </Card> 
            </Col>
            </Row>
            </div>
  }


  NameGroupDialog = () => {
    // console.log(openDialogItem)
    return <CustomDialog2 
      open={this.state.nameGroupDialog}
      title={'Name this Group'}
      hideCancelButtons={true}
      maxWidth={'sm'}
      onClose={() => {
        this.setState({
          nameGroupDialog:false,
          group_name: '',
          delivery_date: '',
        });
        // setOpenDialogItem(null);
      }}
      onOk={() => {
        if (this.state.group_name == '') {
          apiUtil.toast('Group name is required!', 'warning');
          return
        }
        let selectedRow = this.rowData;
        let ids = '';
        if(selectedRow != null && selectedRow != '') {
          selectedRow.map((item, i) => { ids += item.id +','; });
        } 
        ids = ids.slice(0, -1);
        let data = {
          group_name: this.state.group_name ? this.state.group_name.trim() : '',
          delivery_date: this.state.delivery_date,
          orders : ids
        }
        this.callAllocationCreateApi(data,(result) =>{
          if(result) {
            let allocation_groups = this.state.allocation_groups
            allocation_groups.push(result.result)
            apiUtil.toast('Successfully Created', 'check_circle');
            if(result.result.orders && result.result.orders.length > 0) {
              this.callReadApi()
            }
            this.setState({
            nameGroupDialog:false,
            allocation_groups: allocation_groups,
            group_name: '',
            delivery_date: ''
          }, ()=> {
            this.rowData = null;
            this.callGroupNameApi();
          });
          }
          
        })
        
        
      }}
      btnOkText={"Confirm"}
    >
      {/* add openDialog item worker name */}
      <Box>
      <Row>
      <Col xs={12} className="d-flex justify-content-center p-2 align-items-center">
        <div className="w-75 p-2"> 
          <CustomInput type="text" placeholder="E.g. West locations 13-06-2022" className="w-100"
          value={this.state.group_name}
          onChange={e=>{
            this.setState({group_name: e.target.value})
          }}
          />
          </div>
          <div className="w-25 p-2">
          <CustomInput
          placeholder={'DD/MM/YYYY'}
          color={'secondary'}
          value={this.state.delivery_date}
          type={"date"}
          startAdornment={(obj) => {
            return <Box
                onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                }}
            >
                <CalendarIcon />
            </Box>
          }}
          onChange={e=>{
            this.setState({delivery_date: e.target.value})
          }}
      />
      </div>
        </Col>
      </Row>
    </Box>
    </CustomDialog2>
  }

  ExitGroupDialog = () => {
    // console.log(openDialogItem)
    return <CustomDialog2 
      open={this.state.exitGroupDialog}
      title={'Pick existing group'}
      hideCancelButtons={true}
      maxWidth={'sm'}
      onClose={() => {
        this.setState({
          exitGroupDialog:false,
          selected_group: ''
        });
        // setOpenDialogItem(null);
      }}
      onOk={() => {
        if (this.state.selected_group == '') {
          apiUtil.toast('Please selected group!', 'warning');
          return
        }
        let selectedRow = this.rowData;
  
        let ids = '';
        if(selectedRow != null && selectedRow != '') {
          selectedRow.map((item, i) => { ids += item.id +','; });
        } else {
          apiUtil.toast('You must select order!', 'warning');
          this.setState({
            exitGroupDialog: false,
          })
          return
        }
        ids = ids.slice(0, -1);
        let data = {
          orders : ids
        }
        let id = this.state.selected_group
        this.callAllocationUpdateApi(id,data,(result) =>{
          if(result) {
            apiUtil.toast('Successfully Updated', 'check_circle');
            if(result.result.orders && result.result.orders.length > 0) {
              this.callReadApi()
            }
            this.setState({
              exitGroupDialog:false,
              selected_group: ''
            });
            this.rowData = null;
          }
          
        })
      }}
      btnOkText={"Confirm"}
    >
      {/* add openDialog item worker name */}
      <Box>
      <Row>
      <Col xs={12} className="d-flex justify-content-center p-2 align-items-center">
          <Form.Select aria-label="Default select example" className='ms-2'
          value={this.state.selected_group}
          onChange={e=>{
            this.setState({
              selected_group:e.target.value
            })
          }}
          >
              <option>-- Select a group --</option>
              {this.state.allocation_groups.length > 0 && this.state.allocation_groups.map((data) => (
                <option value={data.id} key={data.id}>{data.group_name}</option>
              ))}
            </Form.Select>
        </Col>
      </Row>
    </Box>
    </CustomDialog2>
  }

  activeHandler = (clickedActive) => {
    this.pages = parseInt(clickedActive);
    this.callReadApi();
  };

  render() {
    const cloneData = _.cloneDeep(this.state.data);
    return <Container fluid 
            style={{overflowY: 'auto'}}>
    {  this.state.autoAssign? 
    <Box className="loadingScreen">
      <CircularProgress size={35} className="circular_progress"/>
    </Box> 
   : '' }
      {/* <StickyPageHeader isSmall={false}>
        {this.customStickyPageHeader()}
      </StickyPageHeader> */}
      <Row className="p-4 overflow-hidden">
        <Col xs={12} md={6}>
          <div className="fs-4 font-medium">Sub-Clustering Orders</div>
        </Col>
        <Col xs={12} md={6} className="d-flex justify-content-end align-items-center  max-xs-width-control-1019">
          <Button className="upload-style me-2 max-xs-width-control-1019" 
              onClick={() => {
                this.setState({
                  openDialogBatchUpdate: true
                })
              }}
              >Bulk Update</Button>
            <CustomDateRangePicker
                disabled={this.state.isLoading ? true : false}
                id={'select-dates-step w-50'}
                range={this.state.rangeDate}
                onChange={(range) => {
                  localStorage.setItem(this.pageName + '_rangeDate', JSON.stringify(range));
                  this.pages = 1;
                  this.setState({
                    order_status: '',
                    rangeDate: range,
                    startDate: range.startDate,
                    endDate: range.endDate
                  }, () => {
                    this.callInitApis();
                    // this.callReadApi();
                  });
                }}
              />
            {/* <Button className="upload-style" 
             onClick={() => {
              if(this.rowData == null || this.rowData == "") {
                apiUtil.toast("Plese select order(s) first", "check_circle")
              } else {
              this.setState({
              exitGroupDialog:true
            });}}
            }
            >Add to Exiting Group</Button>
            <Button className="upload-style" 
            onClick={() => {
              if(this.rowData == null || this.rowData == "") {
                apiUtil.toast("Plese select order(s) first", "check_circle")
              } else {
              this.setState({
                nameGroupDialog:true
            })
          }
        }}
            ><FontAwesomeIcon icon={faPlus} />Add New Group</Button> */}
        </Col>
      </Row>
    <Row className="p-4 d-flex justify-content-center">
        <Col xs={12} md={12} lg={3} className="d-flex justify-content-center p-0 m-0 mb-2 position-relative">
        <span className="right-chevron-warpper d-flex justify-content-center w-100 p-0 m-0">
        <div className="chevron-active right-chevron-shape d-flex justify-content-center flex-column align-items-center">
                <div className="chevron-num-style text-center">1</div>
                <div className="chevron-text pt-2">Confirm your clusters</div>
        </div>
        </span>
        </Col>
        <Col xs={12} md={12} lg={3} className="d-flex justify-content-center mb-2 p-0 m-0 position-relative">
        <span className="right-chevron-warpper d-flex justify-content-center w-100 p-0 m-0"
        style={{cursor: "pointer"}}
        onClick={e=>{
          if (!this.state.isLoading) {
            if (this.state.collection_only) {
              this.setState({
                openWarnDialog:true,
                route:"/dashboards/allocation1",
              })
            } else {
              this.props.history.push('/dashboards/allocation1');
            }
          }
        }}
        >
        <div className="right-chevron-shape d-flex justify-content-center flex-column align-items-center">
                <div className="chevron-num-style text-center">2</div>
                <div className="chevron-text pt-2">Assign Driver-Partners</div>
        </div>
        </span>
        </Col>
        <Col xs={12} md={12} lg={3} className="d-flex justify-content-center mb-2 p-0 m-0 position-relative">
        <span className="right-chevron-warpper d-flex justify-content-center w-100 p-0 m-0"
        style={{cursor: "pointer"}}
        onClick={e=>{
          if (!this.state.isLoading) {
            if (this.state.collection_only) {
              this.setState({
                openWarnDialog:true,
                route:"/dashboards/allocation2",
              })
            } else {
              this.props.history.push('/dashboards/allocation2');
            }
          }
        }}
        >
        <div className="right-chevron-shape d-flex justify-content-center flex-column align-items-center">
                <div className="chevron-num-style text-center">3</div>
                <div className="chevron-text pt-2">Sequence Locations</div>
        </div>
        </span>
        </Col>
    </Row>
      {this.customSearch()}
      <Row className="p-4">
        <Col xs={12}>
          {this.state.isLoading ? <Card>
            <div className="p-4 d-flex justify-content-center align-items-center text-center">
              <CircularProgress size={35} />
            </div> 
          </Card> : 
          this.state.data && this.state.data.length > 0 ? 
          this.state.data.map((dd, index) => {
            return <Card className="cd-shadow p-4 mb-4"
            id={
                  [this.state.data[index].collection_reference,
                  this.state.data[index].orders['0'].drop_off_date,
                  this.state.data[index].orders['0'].application_company_id,
                  this.state.data[index].orders['0'].drop_off_worker_id,
                  this.state.data[index].orders['0'].vendor_id,
                  this.state.data[index].orders['0'].order_status_id,
                  index,
                  (this.state.data[index].orders['0'].drop_off_address).replaceAll(',', ''),
                  ]
                }
            onDragOver = {(e)=>{
              e.preventDefault();
              let dataArray = e.currentTarget.id.split(",")
              this.collection_do =dataArray[0];
              this.target_date = dataArray[1];
              this.target_application_company_id = dataArray[2];
              this.target_drop_off_worker_id =  dataArray[3] ? dataArray[3] : null;
              this.target_vendor_id = dataArray[4] ? dataArray[4] : null;
              this.target_status_id = dataArray[5] ? dataArray[5] : null;
              this.index_num = dataArray[6] ? dataArray[6] : null;
              this.target_address =  dataArray[7] ? dataArray[7] : null;
            }}
            onDragEnd={(e)=> {
              // e.preventDefault();
              // console.log("collection_do", this.collection_do)
              this.handleMouseUp();
             let data = this.rowData;
             if(this.state.currentRole == 'BD Admin' || this.state.currentRole == 'Finance Admin') {
               apiUtil.toast('Permission Denied!', 'warning', 'error');
               return;   
             }
             if (index != this.index_num) {
              if (!moment(data.drop_off_date).isBefore(moment(this.target_date)) && data.order_status_id == this.target_status_id && data.vendor_id == this.target_vendor_id && data.drop_off_worker_id == this.target_drop_off_worker_id && data.application_company_id == this.state.user.owner_info.application_company_id && data.application_company_id == this.target_application_company_id && data.drop_off_address.replaceAll(',', '') != this.target_address.replaceAll(',', '')) {
                if(this.collection_do != null && this.collection_do != (data && data.collection_reference)){
                  ['order_status','extra_workers','uploader'].forEach(e => delete  data[e]);
                  // data.drop_off_date = moment( data.drop_off_date).format("YYYY-MM-DD");
                  data.collection_reference = this.collection_do;
                  data.order_packages.map((pack, index) => {
                    if (pack.package_id === 1) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      data.order_packages[index]['name'] = 'XS'
                      data.xs_packages =  data.order_packages[index]['package_quantity']
                    }
                    if (pack.package_id === 2) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      data.order_packages[index]['name'] = 'S'
                      data.s_packages =  data.order_packages[index]['package_quantity']
                    }
                    if (pack.package_id === 3) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      // delete pack['order_id'];
                      // delete pack['id'];
                      data.order_packages[index]['name'] = 'M'
                      data.m_packages =  data.order_packages[index]['package_quantity']
                    }
                    if (pack.package_id === 4) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      // delete pack['order_id'];
                      // delete pack['id'];
                      data.order_packages[index]['name'] = 'L'
                      data.l_packages =  data.order_packages[index]['package_quantity']
                    }
                    if (pack.package_id === 5) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      // delete pack['order_id'];
                      // delete pack['id'];
                      data.order_packages[index]['name'] = 'Pallet'
                      data.pallets =  data.order_packages[index]['package_quantity']
                    }
                    if (pack.package_id === 6) {
                      delete pack['created_at'];
                      delete pack['updated_at'];
                      delete pack['application_company_id'];
                      // delete pack['order_id'];
                      // delete pack['id'];
                      data.order_packages[index]['name'] = 'Oversized Package'
                      data.oversized_packages =  data.order_packages[index]['package_quantity']
                    }
                  })
                  data.is_allocation = true
                    this.callUpdateApi(data.id, data, (row) => {
                      this.rowData = null;
                      this.callReadApi();
                    });
                }
               } else if (data.application_company_id != this.target_application_company_id){
                apiUtil.toast("Own orders to vendor orders are not allow!")
               } else if (data.order_status_id != this.target_status_id){
                apiUtil.toast("Orders  cannot be grouped in clusters with different status")
               } else if (data.drop_off_worker_id != this.target_drop_off_worker_id){
                apiUtil.toast("Orders within the group cannot be assigned to different drivers")
               } else if (data.vendor_id != this.target_vendor_id){
                apiUtil.toast("Different vendor orders are not allow!")
               } else if (moment(data.drop_off_date).isBefore(moment(this.target_date))){
                apiUtil.toast("Delivery date can not be ealier than Pick Up date")
               } else if (data.drop_off_address.replaceAll(',', '') == this.target_address.replaceAll(',', '')){
                apiUtil.toast("Pick up and Delivery address can not be same")
               } else {
                apiUtil.toast("Please check with Operations for changes")
               }
             }
            }}>
                    <div className="d-flex justify-content-between align-items-center max-xs-width-control-1019">
                      <div className="d-flex justify-content-start align-items-center max-xs-width-control-1019">
                        <p className="font-medium fs-5 pb-2 pe-4 max-xs-width-control-1019">{dd.company_name}</p>
                        <p className="fs-6 pb-2 max-xs-width-control-1019">Payout to Driver <span className="bg-light px-4 py-2 rounded ms-2">$ {parseFloat(dd.driver_payout).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                      </div>
                      <div>
                        <div className="d-flex align-items-center max-xs-width-control-700">
                        <div style={{zIndex: 1}}>
                        {(dd.driver_name && apiUtil.customFilter(this.state.status, 'id', dd.orders[0].order_status_id) != null && apiUtil.customFilter(this.state.status, 'id', dd.orders[0].order_status_id).status != 'Assigned To Vendor') && <CustomAutosuggest 
                        className={'no-margin z-index-allocation max-xs-width-control-700 m-2'}
                        placeholder={'- Pick Driver-Partner -'}
                        disabled = {true}
                        value = {dd.driver_name}
                    />}
                    </div>
                        {dd.is_selected? 
                        <div><Button className="delete-style max-xs-width-control-1019 m-2"
                          disabled={this.state.grantedAccess.isPermissionActionAllowed( this.state.currentRole, 'Job Delete') ? false : true }
                          onClick={e=>{
                            let vendored = false;
                            this.DoDialogOrder = {indexNum: index,company_name:dd.company_name,driver_payout:dd.driver_payout,orders: cloneData[index].orders.filter(dd => dd.tableData.checked == true ||  dd.tableData.checked == 'true')}
                            this.tableOrders = dd.orders.filter(dd => dd.tableData.checked == true ||  dd.tableData.checked == 'true');
                            this.DoDialogOrder.orders.sort((a,b) =>  a.job_type - b.job_type )

                            /*if (this.DoDialogOrder.orders['0'].job_type == "Collection") {
                              this.DoDialogOrder.orders = dd.orders
                            }*/
                            this.DoDialogOrder.orders.map(order=> {
                              if (order.application_company_id != this.state.user.owner_info.application_company_id) {
                                vendored = true
                              }
                            })
                            if (vendored) {
                              apiUtil.toast('Please check with Operations for changes', 'check_circle');
                              this.state.data[index].orders.map((obj) => {
                                obj.tableData.checked = false;
                                obj.is_selected = false;
                              });
                              this.state.data[index].is_selected = false
                              this.setState({
                                data: this.state.data,

                              });
                            } else {
                              this.setState({
                                openDeleteDialog : true,
                              })
                            }
                          }}
                          >Delete</Button>
                          <Button className="upload-style max-xs-width-control-1019 m-2"
                          onClick={e=>{
                            let vendored = false;
                            this.DoDialogOrder = {indexNum: index,company_name:dd.company_name, driver_name:dd.driver_name, driver_payout:dd.driver_payout,orders: cloneData[index].orders.filter(dd => dd.tableData.checked == true ||  dd.tableData.checked == 'true')}
                            this.tableOrders = dd.orders.filter(dd => dd.tableData.checked == true ||  dd.tableData.checked == 'true');
                            // this.DoDialogOrder.orders.map(order=> {
                            //   if (order.application_company_id != this.state.user.owner_info.application_company_id) {
                            //     apiUtil.toast('admin customer removed', 'check_circle');
                            //   }
                            // })
                            // if (vendored) {
                            //   apiUtil.toast('Vendor Orders are not allow to duplicate !!', 'check_circle');
                            //   this.state.data[index].orders.map((obj) => {
                            //       obj.tableData.checked = false;
                            //       obj.is_selected = false;
                            //   });
                            //   this.state.data[index].is_selected = false
                            //   this.setState({
                            //     data: this.state.data 
                            //   });
                            // } else {
                              this.setState({
                                openDuplicateDialog : true,
                              })
                            // }

                          }}
                          >Duplicate</Button></div> :  <div><Button className="delete-style max-xs-width-control-1019 m-2" disabled>Delete</Button><Button className="upload-style max-xs-width-control-1019 m-2" disabled>Duplicate</Button></div> }</div>
                        </div>
                    </div>
                    {this.customTable(dd.orders, index)}
                    {(dd.orders && dd.orders.filter(obj => obj.job_type === "Delivery").length <= 0) && dd.orders[0].failed_collection == true ? <div className="d-flex font-medium fs-5 justify-content-center">- No Deliveries have been tied to this pick up yet -</div>: ""}
                  </Card>
          }) :
          <Card className="cd-shadow p-4 text-center"><span className="do_warning fs-6"><FontAwesomeIcon icon={faWarning} style={{color: "#ff9966"}}/>  There is no available Allocation Data For selected Date Range!!</span></Card>
          }
        </Col>
      </Row>
      {  this.state.data && this.state.data.length > 0 ? <Row>
          <Col xs={12} md={12} lg={12} className="px-4 py-2 pagination-position d-flex justify-content-start">
          <Pagination
              active={this.pages}
              size={Math.ceil(this.state.total/ this.limits)}
              step={2}
              onClickHandler={this.activeHandler}
          />
          </Col>
        </Row> : ''}
      {this.customDialog()}
      {this.customDoDialog()}
      {this.deleteDialog()}
      {this.bulkDeleteDialog()}
      {this.customDialogBatchUpdate()}
      {this.duplicateDialog()}
      {this.dialogBatchUpdateConfirm()}
      {this.customWarnDialog()}
      {this.NameGroupDialog()}
      {this.ExitGroupDialog()}
      {this.previewDialog()}
      {this.setTour()}
      {this.skipDialog()}
	  <div id="barcode" style={{display:'none'}}></div>
      <div id="qr" style={{display:'none'}}></div>

    </Container>;
  }
}

export default Allocation;